export default {
  links: [
    {
      path: "/",
      name: "home",
    },

    {
      path: "/my-works",
      name: "my works",
    },
    // {
    //   path: "/gallery",
    //   name: "gallery",
    // },
    {
      path: "/blog",
      name: "blog",
      disabled: true,
    },
    {
      path: "/contact",
      name: "contact",
    },
  ],
}
